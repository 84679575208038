<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <g>
      <rect
        x="49.046"
        y="436.868"
        style="fill:#FED159;"
        width="47.848"
        height="55.504"
      />
      <rect
        x="415.1"
        y="436.868"
        style="fill:#FED159;"
        width="47.848"
        height="55.504"
      />
      <path
        style="fill:#FED159;"
        d="M364.132,44.645v66.391h-40.05V59.753H187.925v51.282h-40.06V44.643
		c0-13.813,11.201-25.015,25.025-25.015h166.226C352.93,19.63,364.132,30.832,364.132,44.645z"
      />
    </g>
    <rect
      x="0"
      y="109.124"
      style="fill:#65B4BB;"
      width="512"
      height="364.467"
    />
    <g>
      <rect
        x="0"
        y="109.124"
        style="fill:#57A3A7;"
        width="39.174"
        height="364.467"
      />
      <path
        style="fill:#57A3A7;"
        d="M511.996,141.02L474.7,306.97c-5.029,22.376-24.899,38.275-47.833,38.275h-8.757h-72.345H166.244
		H93.9h-8.771c-22.934,0-42.804-15.899-47.833-38.275L0,141.02H511.996z"
      />
    </g>
    <path
      style="fill:#65B4BB;"
      d="M511.996,109.12L474.7,275.069c-5.029,22.376-24.899,38.275-47.833,38.275h-8.757h-72.345H166.244
	H93.9h-8.771c-22.934,0-42.804-15.899-47.833-38.275L0,109.12H511.996z"
    />
    <g>
      <rect
        x="93.889"
        y="267.209"
        style="fill:#FED159;"
        width="72.345"
        height="107.176"
      />
      <rect
        x="345.761"
        y="267.209"
        style="fill:#FED159;"
        width="72.345"
        height="107.176"
      />
    </g>
    <g>
      <rect
        x="114.752"
        y="283.695"
        style="fill:#57A3A7;"
        width="30.624"
        height="74.2"
      />
      <rect
        x="366.624"
        y="283.695"
        style="fill:#57A3A7;"
        width="30.624"
        height="74.2"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
